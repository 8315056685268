/* src/style/Overlay.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000; /* Higher than header's z-index */
  animation: fadeIn 0.3s forwards;
}

.overlayContent {
  background: white;
  padding: 40px;
  text-align: center;
  width: auto;
  max-width: 800px;
  height: auto;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  transform: scale(0.9);
  animation: scaleIn 0.3s forwards;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.overlayButton {
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px; /* Increased font size for better visibility */
}

.overlayButton:hover {
  background-color: #0056b3;
}

.productSummary {
  margin-top: 20px;
}

.productSummary ul {
  list-style-type: none;
  padding: 0;
}

.productSummary li {
  margin-bottom: 10px;
}

.overlayImage {
  width: 150px; /* Adjust the size as needed */
  height: auto;
}

.overlayHr {
  width: 80%;
  border: none;
  border-top: 2px solid #ccc; /* Light gray line */
  margin: 0px 0px 20px; /* Space above and below the hr */
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  to {
    transform: scale(1);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .overlayContent {
    width: 90%;
    max-width: 400px; /* Set a maximum width appropriate for mobile screens */
    padding: 20px;
    box-sizing: border-box; /* Ensure padding is included in the width */
  }

  .overlayHr {
    width: 100%;
  }

  .overlayImage {
    width: 120px; /* Adjust image size for smaller screens */
  }
}
