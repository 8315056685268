/* Checkout.module.css */

/* Wrapper with consistent margins */
.checkoutWrapper {
  margin: 20px 40px;
}

/* Container flex settings */
.checkoutContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Left and Right sections */
.checkoutLeft,
.checkoutRight {
  padding: 20px;
}

/* Left section flex settings */
.checkoutLeft {
  flex: 1;
}

/* Right section flex settings and border */
.checkoutRight {
  flex: 1;
  border-left: 1px solid #ddd;
}

/* Form section */
.checkoutSection {
  margin-bottom: 20px;
}

/* Form row styling */
.checkoutFormRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Input and select styling */
.checkoutInput,
.checkoutSelect {
  flex: 1 1 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box; /* Ensures padding is included in width */
  width: 100%; /* Ensure full width */
}

/* Adjust inputs for larger screens */
@media (min-width: 480px) {
  .checkoutInput,
  .checkoutSelect {
    flex: 1;
  }
}

/* Pay Now button styling */
.payNowButton {
  background-color: #000;
  color: white;
  padding: 15px;
  width: 100%;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
}

/* Cart Summary Styling */
.cartSummary {
  padding: 20px;
}

.bagIcon {
  width: 20px;
  height: 20px;
}

.cartList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cartItemInfo {
  display: flex;
  align-items: center; /* Vertically centers the image */
}

.cartItemDetails p {
  margin-left: 10px;
  font-size: 14px;
}

.cartImg {
  width: 60px;
  height: 80px;
  object-fit: cover;
}

.cartItemPrice {
  display: flex;
  align-items: center;
}

.cartSubtotal {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-top: 20px;
}

/* Collapsible Component Styling */
.collapsible {
  margin-bottom: 20px;
}

/* Header Styling */
.checkoutHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  background-color: #ffffff; /* White background */
  position: sticky;
  top: 0;
  z-index: 1002; /* Ensure it stays above other elements */
  border-bottom: 1px solid #eaeaea; /* Optional: Adds a subtle separator */
}

.backButton {
  position: absolute;
  left: 0px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.backIcon {
  width: 16px;
  height: 16px;
  fill: #000; /* Adjust color as needed */
}

.brandText {
  font-weight: bold;
  font-size: 18px;
  font-family: "Didot Bold", serif; /* Ensure this font is loaded */
  color: #000;
  opacity: 0.7;
  cursor: pointer;
}

.collapsibleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /* background-color: #f5f5f5; */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.collapsibleHeader:hover {
  background-color: #eaeaea;
}

.collapsibleIcon {
  transition: transform 0.3s ease;
}

.collapsibleOpen .collapsibleIcon {
  transform: rotate(180deg);
}

.collapsibleContent {
  padding: 10px;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

/* Payment Information Box Styling */
.paymentBox {
  width: 100%; /* Ensures full width within the parent container */
  box-sizing: border-box; /* Includes padding and border in the element's total width */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  /* background-color: #f9f9f9; Light grey background for contrast */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.paymentBoxHeader {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333; /* Darker text for readability */
}

.paymentBoxContent {
  font-size: 14px;
  color: #555; /* Medium grey for text */
  line-height: 1.5;
}
.paymentRadio {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  cursor: not-allowed; /* Indicates non-interactive */
}

.paymentLabel {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Always show cart-summary on desktop */
@media (min-width: 768px) {
  .checkoutContainer {
    flex-direction: row;
  }

  .checkoutRight {
    border-left: 1px solid #ddd;
  }

  /* Hide collapsible header on desktop */
  .collapsibleHeader {
    display: none;
  }

  /* Ensure collapsible content is always visible on desktop */
  .collapsibleContent {
    display: block !important;
    border: none;
    padding: 0;
  }

  /* Adjust cart-summary padding */
  .cartSummary {
    padding: 0;
  }

  /* Ensure subtotal is correctly positioned */
  .cartSubtotal {
    margin-top: 20px;
  }
}

/* Responsive Design for Mobile */
@media (max-width: 767px) {
  .checkoutContainer {
    flex-direction: column;
  }

  .checkoutRight {
    border-left: none;
    margin-top: 20px;
  }

  /* Collapsible adjustments for mobile */
  .collapsibleContent {
    display: none;
  }

  .collapsibleOpen .collapsibleContent {
    display: block;
  }
}

/* Add a grey background for the collapsible section on mobile */
.collapsibleMobile {
  /* background-color: #f5f5f5; Light grey background */
  padding: 10px; /* Optional padding */
  border-radius: 5px; /* Optional border-radius */
}

.customisationStatus {
  font-style: italic;
}
