@keyframes loadingAnimation {
  0% {
    background-color: rgba(112, 112, 112, 0.2);
  }
  50% {
    background-color: rgba(112, 112, 112, 0.4);
  }
  100% {
    background-color: rgba(112, 112, 112, 0.2);
  }
}

.item-container {
  width: 90%;
  margin: auto;
  text-align: center;
}

.clear-selections-button {
  background-color: #000000; /* A vibrant red to indicate a reset action */
  color: #ffffff; /* White text for contrast */
  font-family: "Georgia", serif; /* Consistent with other text styles */
  font-size: 12px; /* Consistent font size */
  padding: 5px 10px; /* Comfortable padding */
  border: none; /* Remove default border */
  border-radius: 4px; /* Slightly rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  display: inline-flex; /* Align icon and text properly */
  align-items: center; /* Center items vertically */
}

.clear-selections-button:hover {
  background-color: #000000; /* Lighter red on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.clear-selections-button:active {
  transform: scale(0.95); /* Slightly shrink on click */
}

.clear-selections-button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5); /* Add a custom focus ring */
}

.clear-selections-button .clear-icon {
  width: 16px; /* Icon size */
  height: 16px; /* Icon size */
  margin-right: 8px; /* Space between icon and text */
}

.hide-on-desktop {
  display: block;
}

.item-slider {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.order-info-text {
  font-size: 12px;
}

.item-product-wrapper {
  display: flex;
  flex-direction: row; /* Default to row for horizontal swipe on mobile */
  transition: transform 0.5s ease;
  width: 100%;
}

.container-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-product-container {
  background-color: rgba(112, 112, 112, 0.2);
  /* opacity: 20%; */
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: loadingAnimation 1.5s infinite; /* Apply the animation */
}

.item-product-container:focus,
.item-product-container:hover {
  outline: none;
  cursor: pointer;
}

.item-product-details {
  margin-top: 10px;
  text-align: left;
  margin-left: 10px;
  position: relative;
}

.item-new-in,
.item-name,
.item-price {
  font-family: "Didot bold";
  margin-bottom: 10px;
}

.item-new-in {
  font-size: 11px;
  opacity: 0.7;
  color: rgba(112, 112, 112, 0.8);
}

.item-name {
  font-size: 12px;
  opacity: 1;
  color: rgba(112, 112, 112, 1);
}

.item-price {
  font-size: 12px;
  opacity: 1;
  color: rgba(112, 112, 112, 1);
}

.item-container-label {
  font-size: 24px;
  color: white;
}

.item-slide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.item-slide-button.prev {
  left: 10px;
}

.item-slide-button.next {
  right: 10px;
}

.item-slide-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.info-text {
  color: #707070;
  opacity: 0.9;
  font-size: 12px;
  padding: 20px 10px 4px 0px;
  font-family: "Georgia";
  text-align: left;
}

.text-details {
  margin-top: 40px;
  text-align: center;
}

.text-details > div {
  margin-bottom: 15px;
}

.text-item-name {
  font-size: 16px;
  font-family: "Georgia";
  color: #707070;
}

.text-new-in-small {
  font-size: 12px;
  font-family: "Georgia";
  color: #707070;
}

.text-new-in-large {
  font-family: "Georgia";
  color: #707070;
}

.order-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
  font-family: "Georgia";
  color: #707070;
}

.clock-icon {
  margin-right: 10px;
}

.order-time {
  color: rgba(14, 137, 19, 0.5);
  font-size: 12px;
}

/* Styled horizontal rule */
.styled-hr {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 95%;
  margin: 0 auto;
}

.styled-hr-top {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 93%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.styled-hr-bottom {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 93%;
  margin: 10px auto 0 auto;
  padding-bottom: 15px;
}

/* Styled horizontal rule */
.styled-hr-small {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 60%;
  margin: 20px auto;
}
/* Styled horizontal rule */
.styled-hr-small-selected {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 95%;
  margin: 20px auto;
}

.client-selection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.client-box {
  width: 90%;
  padding: 15px 20px;
  margin: 0 2px;
  text-align: center;
  font-family: "Georgia";
  font-size: 14px;
  cursor: pointer;
}

.client-box.selected {
  background-color: #000000;
  color: white;
}

.client-box:not(.selected) {
  background-color: white;
  color: #000000;
  border: 1px solid #707070;
}

.client-info {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.client-code-text {
  color: #707070;
  opacity: 0.9;
  font-size: 12px;
  font-family: "Georgia";
}

.client-code-box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  margin: 10px auto;
  padding: 15px 0px 10px 0px;
  background-color: white;
  border: 1px solid #b0b0b0; /* Slightly lighter border color */
  cursor: pointer;
}

.client-code-input {
  width: 16px;
  height: 16px;
  border: none; /* Remove underline */
  font-size: 16px;
  text-align: center;
  color: #707070;
  background-color: transparent;
  outline: none;
}

.client-code-input::placeholder {
  color: #707070;
  opacity: 0.5;
}

.client-welcome-text {
  color: #707070;
  opacity: 0.9;
  font-size: 14px;
  padding: 25px 35px 4px 35px;
  font-family: "Georgia";
  text-align: left;
}

.client-error-text {
  color: #a21b1b;
  opacity: 0.9;
  font-size: 14px;
  padding: 25px 35px 4px 35px;
  font-family: "Georgia";
  text-align: left;
}

.new-client-form {
  text-align: left;

  padding-left: 20px;
  padding-right: 25px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group label {
  flex: 0.5;
  font-family: "Georgia";
  font-size: 14px;
  color: #707070;
}

.form-input {
  flex: 0.5;
  border: none;
  border-bottom: 0.8px solid #707070;
  outline: none;
  font-size: 14px;
  font-family: "Georgia";
  color: #707070;
  padding-right: 20px; /* Make space for the icon */
  appearance: none; /* Remove default dropdown arrow */
  background: url("../icons/down.svg") no-repeat right; /* Custom dropdown arrow */
  background-size: 8px; /* Size of the custom arrow */
  background-position: right 10px center; /* Adjust arrow position */
  -webkit-appearance: none; /* Remove default arrow for WebKit browsers */
  -moz-appearance: none; /* Remove default arrow for Mozilla browsers */
  appearance: none; /* Remove default arrow for standard browsers */
  cursor: pointer;
}

.form-icon {
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: -15px; /* Adjust based on padding */
  padding-bottom: 5px;
}

.customize-options {
  text-align: left;
  padding-left: 20px;
  padding-right: 25px;
}

.customize-category {
  margin-bottom: 40px;
}

.customize-category h4 {
  font-family: "Georgia";
  font-size: 16px;
  color: #707070;
  margin-bottom: 10px;
}

.customize-option {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure items are spaced correctly */
  margin-bottom: 10px;
}

.customize-option span {
  flex: 1;
  font-family: "Georgia";
  font-size: 14px;
  color: #707070;
  font-weight: 500;
  padding: 2px 0;
}

.customize-box {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #f1f1f1;
  margin-right: 10px; /* Space between the box and radio button */
  vertical-align: middle; /* Align with text */
  border: 1px solid #ccc; /* Add a border to match the style */
  box-sizing: border-box; /* Ensure padding and border are included in width and height */
  flex-shrink: 0; /* Prevent the box from shrinking */
  margin-top: 4px;
}

.message-title {
  text-align: left;
  padding-left: 20px;
  padding-right: 25px;
  font-family: "Georgia";
  font-size: 14px;
  color: #707070;
  margin-bottom: 25px;
}

.message-options {
  text-align: left;
  padding-left: 20px;
  padding-right: 25px;
}

.message-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.message-option span {
  flex: 1;
  font-family: "Georgia";
  font-size: 14px;
  color: #707070;
}

/* Customize the radio buttons */
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #000;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  margin-right: 15px; /* Adjust as necessary for spacing */
}

input[type="radio"]:checked::before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 2px;
  left: 2px;
}

.bottom-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px; /* Adjust padding for both ends */
  margin: 20px 0;
}
.client-code-button-container {
  display: flex;
  align-items: center;
  width: 97%;
}

.client-code-button {
  background-color: #000000;
  color: white;
  font-family: "Georgia";
  font-size: 14px;
  padding: 15px 20px; /* Match the padding of the client-code-button */
  text-align: center;
  cursor: pointer;
  flex: 1;
}

.client-code-box-small {
  background-color: #000000;
  margin-left: 5px; /* Adjust the margin as needed */
  padding: 15px 20px; /* Match the padding of the client-code-button */
  font-size: 14px;
}

.dark-star-icon {
  width: 14px; /* Adjust size as needed */
  height: 14px; /* Adjust size as needed */
  cursor: pointer;
}
.extras-section {
  margin-top: 85px;
}

.extra-shop {
  text-align: center;
}

.extra-shop h2 {
  font-weight: 500;
  font-size: 20px;
}

.shop-the-look {
  margin-top: 85px;

  font-family: "Georgia";
  font-size: 24px;
  color: #707070;
}

/* Ensure the products grid is centered */
.products-grid {
  display: flex;
  gap: 5px; /* Adjust the gap for desired spacing */
  justify-content: center; /* Center items horizontally */
}

.products-grid.centered-2 {
  justify-content: center;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
}

.products-grid.centered-3 {
  justify-content: center;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
}

.product-wrapper {
  position: relative;
}

.product-container {
  background-color: rgba(112, 112, 112, 0.2);
  /* opacity: 20%; */
  width: 100%;
  height: 400px;
  margin-top: 10px;
  animation: loadingAnimation 1.5s infinite; /* Apply the animation */
}

.product-container.loading::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(106, 95, 95, 0.2); /* Placeholder color */
  position: absolute;
  top: 0;
  left: 0;
}

.product-details {
  margin-top: 10px;
  text-align: left;
  position: relative;
  margin-left: 10px;
}

.new-in,
.item-name,
.price {
  font-family: "Georgia";
  margin-bottom: 10px; /* Add space between product details */
  font-weight: 500;
  font-size: 12px;
}

.new-in {
  font-size: 11px;
  opacity: 0.7;
  color: rgba(112, 112, 112, 0.8);
}

.spinner-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner2 {
  width: 10px;
  height: 10px;
  border: 2px solid rgba(112, 112, 112, 0.2); /* Light grey */
  border-top: 2px solid rgba(112, 112, 112, 1); /* Darker grey */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spin Keyframes */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.item-name {
  font-size: 12px;
  opacity: 1;
  color: rgba(112, 112, 112, 1);
}

p .collapsible-mobile {
  text-align: left;
}

.price {
  font-size: 12px;
  opacity: 1;
  color: rgba(112, 112, 112, 1);
}

.unfilled-star {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.selected-for-you {
  text-align: center;
  margin-bottom: 55px;
}

.selected-for-you-title {
  font-family: "Georgia";
  font-size: 20px;
  color: #707070;
  font-weight: 500;
}

.selected-products-grid {
  display: grid;
  gap: 5px;
  margin-top: 20px;
}

.selected-product-wrapper {
  position: relative;
}

.selected-product-container {
  background-color: rgba(112, 112, 112, 0.2);
  /* opacity: 20%; */
  width: 100%;
  height: 400px;
  margin-top: 10px;
  animation: loadingAnimation 1.5s infinite; /* Apply the animation */
}

.selected-product-details {
  margin-top: 10px;
  text-align: left;
  margin-left: 10px;
  position: relative;
}

.separator {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 100%;
  margin-top: 20px;
}

.discover-text {
  font-family: "Georgia";
  font-size: 14px;
  color: rgba(112, 112, 112, 0.8);
  margin-top: 30px;
  padding: 0 35px;
  margin-bottom: 30px;
}

.item-name {
  font-family: "Georgia";
  font-size: 12px;
  opacity: 1;
  font-weight: 400;
  color: rgba(112, 112, 112, 1);
}

/* Media Queries */

@media (max-width: 320px) {
  .product-details {
    margin-left: 5px;
  }

  .product-container {
    height: 220px !important;
    width: 157px !important;
    /* width: auto; */
  }

  .selected-for-you-title {
    margin-top: 55px;
  }

  .left-column-15 {
    display: none;
  }

  .client-box {
    width: 100%;
    padding: 15px 5px;
  }

  .bottom-row {
    padding: 0 10px;
  }

  .client-code-button-container {
    width: 100%;
  }

  .selected-products-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
  }
  .selected-product-container {
    height: 220px;
  }

  .order-info {
    padding: 0 20px;
  }

  .item-product-container {
    height: 500px;
  }

  .item-container {
    width: 100%; /* Full width on mobile */
  }
}

@media (min-width: 321px) and (max-width: 425px) {
  .product-container {
    height: calc(240px + ((100vw - 321px) / (425 - 321)) * (350 - 280));
    width: 170px !important;
  }

  .left-column-15 {
    display: none;
  }

  .client-box {
    width: 100%;
    padding: 15px 5px;
  }

  .selected-for-you-title {
    margin-top: 55px;
  }

  .bottom-row {
    padding: 0 10px;
  }

  .client-code-button-container {
    width: 100%;
  }

  .selected-products-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
  }
  .selected-product-container {
    height: calc(230px + ((100vw - 321px) / (425 - 321)) * (330 - 290));
  }

  .order-info {
    padding: 0 20px;
  }

  .item-product-container {
    height: calc(500px + ((100vw - 321px) / (425 - 321)) * (550 - 500));
  }

  .item-container {
    width: 100%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .item-container {
    width: 100%; /* Full width on mobile */
  }

  .left-column-15 {
    display: none;
  }

  .products-grid {
    width: 100%;
  }

  .selected-for-you-title {
    margin: 55px 10px 0;
  }

  .product-container {
    height: calc(290px + ((100vw - 426px) / (768 - 426)) * (370 - 250));
    width: 210px !important;
  }

  .selected-product-container {
    height: calc(290px + ((100vw - 426px) / (768 - 426)) * (520 - 350));
  }
  .item-product-container {
    height: calc(100vh - 270px); /* Adjusted height for mobile devices */
  }

  .bottom-row {
    flex-direction: row; /* Ensure flex-direction is row */
    padding: 0 20px; /* Adjust padding for both ends */
  }

  .client-code-button {
    width: 100%; /* Take full width */
  }

  .selected-products-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .products-grid.centered-2,
  .products-grid.centered-3 {
    grid-template-columns: repeat(
      2,
      minmax(0, 1fr)
    ); /* Ensure 2 items per row for both classes */
    justify-content: center;
    gap: 20px; /* Ensure gap between items is consistent */
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .selected-for-you-title {
    margin-top: 55px;
  }

  .item-product-container {
    height: calc(600px + ((100vw - 769px) / (1023 - 769)) * (700 - 600));
  }

  .product-container {
    height: calc(250px + ((100vw - 769px) / (1023 - 769)) * (350 - 300));
    width: 215px !important;
  }

  .left-column-15 {
    display: none;
  }

  .product-details {
    max-width: 100% !important;
    margin-left: 10px;
  }

  .selected-product-container {
    height: calc(300px + ((100vw - 769px) / (1023 - 769)) * (400 - 330));
    max-width: 250px !important;
  }

  .selected-products-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* New CSS for desktop layout */
@media (min-width: 1024px) {
  .item-slider {
    height: calc(100vh); /* Ensure it takes the full viewport height */
  }

  .hide-on-desktop {
    display: none;
  }
  .item-product-wrapper {
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease;
    height: auto;
  }

  .item-product-container {
    background-color: rgba(112, 112, 112, 0.2);
    /* opacity: 20%; */
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: loadingAnimation 1.5s infinite; /* Apply the animation */
  }

  .product-container {
    height: calc(350px + ((100vw - 1024px) / (1440 - 1024)) * (20));
    width: 240px !important;
  }

  .product-details {
    width: 100%;
    margin-left: 10px;
  }

  .selected-products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .selected-product-container {
    height: 450px;
  }

  .selected-product-details {
    width: 100%;
  }

  /* Container for the top section */
  .top-section {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }

  /* Column classes */
  .left-column {
    flex: 0 0 50%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }

  .thumbnail-wrapper {
    width: 50%; /* or specific width */
    height: 100px; /* retain the original height */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(179, 170, 170, 0.648);
    opacity: 20%;

    margin-bottom: 10px;
  }

  .thumbnail-wrapper.active {
    background-color: rgba(179, 170, 170, 0.648);
  }

  .thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slider-indicator {
    cursor: pointer;
  }

  .slider-indicator.active .thumbnail-wrapper {
    background-color: rgba(112, 112, 112, 1);
    opacity: 100%;
  }

  .left-column-15 {
    flex: 0 0 15%;
    box-sizing: border-box;
    position: relative; /* Add this line */
  }

  .vertical-ruler {
    position: absolute;
    left: 75%;
    top: 0;
    bottom: 0;
    height: calc(100vh); /* Ensure it takes the full viewport height */
  }

  .vertical-ruler-section {
    position: absolute;
    left: 75%;
    width: 2px;
    background-color: rgba(112, 112, 112, 0.2);
  }

  .vertical-ruler-section.highlighted {
    background-color: rgba(112, 112, 112, 1);
    width: 2px; /* Increase the thickness when highlighted */
  }

  .left-column-35 {
    flex: 0 0 85%;
    box-sizing: border-box;
  }

  .top-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .text-details {
    margin-top: 80px;
  }

  .left-column,
  .right-column {
    flex: 0 0 50%;
    box-sizing: border-box;
  }

  .left-column {
    display: flex;
    flex-direction: row;
    align-items: start;
  }

  .styled-hr-small-selected {
    width: 100%;
  }

  .right-column {
    max-height: calc(
      100vh + 150px
    ); /* Adjust this value to match the left column height */
    overflow-y: auto; /* Enable vertical scrolling */

    padding-left: 80px;
    padding-right: 70px;
    flex: 0 0 50%;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .item-slider,
  .text-details {
    width: 100%;
  }

  .item-product-wrapper {
    width: 100%;
  }

  .item-product-container {
    height: 100vh; /* Full viewport height */
  }

  .text-details {
    text-align: center; /* Center align text */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items */
  }

  .text-item-name,
  .text-new-in-small,
  .text-new-in-large {
    text-align: center; /* Center align text */
  }

  .order-info,
  .customize-options,
  .client-selection,
  .client-info,
  .message-title,
  .message-options,
  .bottom-row,
  .extras-section {
    text-align: left;
    width: 100%;
  }

  .bottom-row {
    padding: 0;
  }

  .client-selection {
    margin: 0;
  }

  .styled-hr-top,
  .styled-hr-bottom {
    width: 97%;
  }

  .shop-the-look,
  .products-grid,
  .selected-for-you,
  .selected-products-grid,
  .discover-text {
    width: 100%;
  }

  .extra-shop {
    margin-top: 25px;
    text-align: center;
    padding: 20px 0; /* Add padding for better spacing */
  }

  .client-selection {
    width: 97%;
    margin: 0 auto;
  }

  .client-code-text {
    text-align: center; /* Center align text */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .products-grid.centered-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .products-grid.centered-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1441px) {
  .selected-products-grid {
    grid-template-columns: repeat(
      5,
      1fr
    ); /* Adjust to 5 columns for larger screens */
    gap: 10px;
  }
  .selected-product-container {
    height: calc(430px + ((100vw - 1440px) / (1920 - 1440)) * (500 - 470));
  }
  .selected-product-details {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .extras-section,
  .collapsible, /* Add your collapsible class here if different */
  .customize-options,
  .info-text {
    text-align: left;
  }
}
