html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Georgia", serif;
}

.search-results-container-unique {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Make the container take full viewport height */
  box-sizing: border-box;
  overflow: hidden; /* Prevent overall vertical scrolling */
}

.search-results-header-unique {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-button-unique {
  display: flex;
  align-items: center;
}

.back-icon-unique {
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.search-results-text-unique {
  margin-left: 20px; /* Horizontal spacing */
  font-family: "Georgia", serif;
  font-size: 18px;
}

.search-container-unique {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
}

.icon-unique {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.search-input-unique {
  border: none;
  outline: none;
  margin-left: 8px;
  font-family: "Didot Bold";
  font-weight: 100;
  font-size: 14px;
  color: #707070;
  background: transparent;
  width: 200px; /* Adjust the width as needed */
}

.search-results-content-unique {
  display: flex;
  flex: 1; /* Take the remaining space */
  overflow: hidden; /* Prevent overall vertical scrolling */
}

.left-section-unique {
  flex: 0 0 70%; /* 70% width */
  padding: 10px 15px 10px 15px; /* Padding from top, left, right, minimal at the bottom */
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent overall vertical scrolling */
}

.product-list-unique {
  flex: 1; /* Allow the product list to take up remaining space */
  overflow-y: auto; /* Make the product list scrollable */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px; /* Increased gap between products */
  align-content: start; /* Align items to the start to prevent stretching */
  padding: 0 20px;
}

.right-section-unique {
  flex: 0 0 30%; /* 30% width */
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.right-content-container-unique {
  background-color: #fff;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-edit-product-header-unique {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.search-add-image-unique {
  cursor: pointer;
  font-family: "Georgia", serif;
  font-size: 14px;
  color: #000;
  border: 1px solid #a0a0a0;
  padding: 5px 10px;
  background-color: #fff;
}

.search-new-container-unique {
  width: 250px;
  height: 350px;
  background-color: rgba(112, 112, 112, 0.5);
  opacity: 0.2;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.search-new-containers-unique {
  width: 250px;
  height: 350px;
  opacity: 0.2;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-image-unique {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: "Georgia", serif;
  font-size: 18px;
  color: #292727;
}

.search-image-item-wrapper-unique {
  display: flex;
  transition: transform 0.25s ease-in-out;
  width: 400%; /* Ensure it does not cause horizontal overflow */
}

.search-image-item-unique {
  flex: 0 0 100%; /* Ensure each item takes the full width of the container */
  height: 100%; /* Ensure items take the full height */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.search-image-item-unique .search-cancel-icon-unique {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  padding: 5px;
  font-size: 16px;
  line-height: 16px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.search-pagination-container-unique {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.search-pagination-circle-unique {
  width: 10px;
  height: 10px;
  background-color: #707070;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.search-pagination-circle-unique.active {
  background-color: #000;
}

.search-product-details-unique {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.search-detail-row-unique {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.search-detail-label-unique {
  font-weight: bold;
  font-family: "Georgia", serif;
  font-size: 14px;
  flex: 0 0 40%;
}

.search-detail-info-unique {
  font-family: "Georgia", serif;
  color: #707070;
  font-size: 14px;
  flex: 0 0 60%;
}

.search-detail-info-unique input,
.search-detail-info-unique select {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  font-family: "Georgia", serif;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f9f9f9;
  margin-top: 5px;
}

.search-detail-info-unique input:focus,
.search-detail-info-unique select:focus {
  border-color: #707070;
  outline: none;
  background-color: #fff;
}

.search-asset-containers-row-unique {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.search-asset-container-unique {
  width: 100px;
  height: 150px;
  background-color: #f0f0f0;
  border: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Georgia", serif;
  font-size: 12px;
  color: #707070;
  position: relative;
}

.search-asset-container-unique .search-cancel-icon-unique {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
  line-height: 14px;
}

.search-add-asset-unique {
  cursor: pointer;
  font-family: "Georgia", serif;
  font-size: 14px;
  color: #000;
  border: 1px solid #a0a0a0;
  padding: 10px;
  background-color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.search-button-container-unique {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: sticky;
  bottom: 0;
}

.search-edit-update-button-unique,
.search-delete-button-unique {
  width: calc(50% - 5px);
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-family: "Georgia";
  font-size: 14px;
  box-sizing: border-box;
}

.search-edit-update-button-unique {
  background-color: #000;
  color: white;
  border: 1px solid #707070;
}

.search-delete-button-unique {
  background-color: #fff;
  color: #000;
  border: 1px solid #d3d3d3;
}

.search-no-products-selected-unique {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: "Georgia", serif;
  font-size: 18px;
  color: #707070;
}
