.product-management-container {
  padding: 20px 20px 0 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  font-size: 18px;
  font-family: "Georgia", serif;
  margin-bottom: 0;
}

.search-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
}

.icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.search-input {
  border: none;
  outline: none;
  margin-left: 8px;
  font-family: "Didot Bold";
  font-weight: 100;
  font-size: 14px;
  color: #707070;
  background: transparent;
  width: 200px;
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.product-management-title {
  font-size: 24px;
  font-family: "Georgia", serif;
  margin-top: 0;
}

.add-product-container {
  display: flex;
  align-items: center;
}

.add-product-box {
  width: 150px;
  padding: 10px;
  text-align: center;
  font-family: "Georgia";
  font-size: 14px;
  cursor: pointer;
  background-color: #000;
  color: white;
  border: 1px solid #707070;
}

.content-sections {
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-top: 20px;
}

.left-section {
  flex: 1; /* Adjusted to take full width */
  height: calc(100% - 2px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.product-list {
  flex: 1;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  align-content: start;
  padding: 0 16px;
}

.tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
}

.tab {
  margin-right: 20px;
  cursor: pointer;
  font-family: "Georgia", serif;
  font-size: 16px;
  color: #707070;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.tab.active {
  background-color: #000;
  color: #fff;
  border-color: #707070;
}

.sort-container {
  display: flex;
  align-items: center;
}

.sort-container span {
  margin-right: 10px;
  font-family: "Georgia", serif;
  font-size: 16px;
  color: #707070;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.down-icon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px; /* Ensure the dropdown is wide enough */
  border-radius: 4px; /* Add some rounding for a cleaner look */
  right: 3px;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none;
  border: none;
  width: 100%; /* Ensure the button takes full width */
  text-align: left; /* Align text to the left */
  cursor: pointer;
}

.dropdown-content button:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
