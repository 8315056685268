/* src/style/VideoComponent.module.css */

.videoContainer {
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9f9; /* Light background for contrast */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center;
}

.videoTitle {
  font-family: "Didot Bold", serif; /* Added fallback font */
  font-size: 24px; /* Increased for better visibility */
  margin: 15px 0;
  color: #707070;
}

.videoResponsive {
  position: relative;
  padding-bottom: 42.85%; /* 21:9 Aspect Ratio (9/21 * 100) */
  padding-top: 0; /* Removed to eliminate unnecessary spacing */
  height: 0;
  width: 100%;
  max-width: 1000px; /* Increased for wider displays */
  margin: 0 auto; /* Center the video container */
  overflow: hidden; /* Ensure child elements don't spill out */
}

.videoIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* Remove iframe border */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .videoResponsive {
    max-width: 800px; /* Adjust max-width for large tablets/desktops */
  }
}

@media (max-width: 800px) {
  .videoTitle {
    font-size: 20px; /* Slightly smaller on medium screens */
  }

  .videoResponsive {
    max-width: 100%; /* Full width on smaller screens */
    padding-bottom: 42.85%; /* Maintain 21:9 ratio */
  }
}

@media (max-width: 600px) {
  .videoTitle {
    font-size: 18px; /* Smaller font on mobile */
  }

  .videoContainer {
    padding: 20px 10px; /* Reduced padding for mobile */
  }

  .videoResponsive {
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio (9/16 * 100) for mobile */
  }
}
