.product-display-container {
  width: 250px; /* Fixed width */
  padding: 10px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: 15px;
}

.product-display-container.selected {
  border-color: #000;
  background-color: #f0f0f0;
}

.image-container {
  width: 100%;
  height: 320px;
  background-color: rgba(112, 112, 112, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px; /* Space between image and text */
  overflow: hidden; /* Ensure image fits within the container */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
}

.product-info {
  font-family: "Georgia", serif;
}

.product-name {
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
  word-wrap: break-word; /* Allow text to wrap */
}

.product-price,
.product-sold,
.shop-the-look {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  padding-top: 12px;
}

.product-price span,
.product-sold span,
.shop-the-look span {
  font-weight: normal; /* Ensure nested span text is not bold */
}

.product-sold {
  margin-top: 5px; /* Add space between price and sold */
}

.shop-the-look {
  margin-top: 5px; /* Add space between sold and shop the look */
}
