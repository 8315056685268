/* dashboard.css */

.dashboard-container {
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto; /* Enable scrolling within the dashboard container */
}

.date {
  font-size: 18px;
  font-family: "Georgia", serif;
  margin-bottom: 40px;
  flex-shrink: 0; /* Prevent date from shrinking */
}

.statistics {
  font-size: 24px;
  font-family: "Georgia", serif;
  margin-top: 40px;
  flex-shrink: 0; /* Prevent statistics title from shrinking */
}

.stats-container {
  display: flex;
  margin-top: 20px;
  flex-shrink: 0; /* Prevent stats-container from shrinking */
}

.stats-box {
  background: #ffffff;
  padding: 15px 10px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  margin: 0 10px;
  max-width: 150px;
}

.stats-number {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.stats-label {
  font-size: 14px;
  font-family: "Georgia", serif;
  font-weight: 300;
}

.additional-containers {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap; /* Allow wrapping if needed */
  flex-shrink: 0; /* Prevent this container from shrinking */
}

.container-left,
.container-right {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin: 10px;
  flex: 1 1 45%; /* Allow containers to grow and shrink, initial width 45% */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* other properties */
}

.dropdown-content .dropdown-item {
  /* existing styles */
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-icon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  flex-shrink: 0; /* Prevent items-container from shrinking */
}

.item-box {
  background-color: #efefef;
  width: 85px;
  height: 140px;
  border-radius: 8px;
  margin: 15px 5px 0px 5px;
}

.item-numbers {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-shrink: 0; /* Prevent item-numbers from shrinking */
}

.item-number {
  font-size: 14px;
  text-align: center;
  width: 85px;
}

.new-dashboard-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin: 30px 10px 0 10px;
  flex-shrink: 0; /* Prevent this container from shrinking */
}

.new-dashboard-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.refresh {
  color: #707070;
  cursor: pointer;
}

.customer-table-container {
  max-height: 180px;
  overflow-y: auto;
}

.customer-table {
  width: 100%;
  border-collapse: collapse;
}

.customer-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  padding: 10px 0;
  align-items: center;
  text-align: center;
}

.customer-header {
  font-weight: bold;
  border-bottom: 2px solid #efefef;
}

.customer-row:not(.customer-header) {
  border-bottom: 1px solid #efefef;
  font-size: 12px;
}

.customer-row > div {
  text-align: center;
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .date {
    margin-bottom: 20px;
  }

  .statistics {
    margin-top: 20px;
  }

  .stats-container {
    flex-direction: column;
    align-items: center;
  }

  .stats-box {
    margin: 10px 0;
    width: 90%;
    padding: 10px;
  }

  .stats-number {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .stats-label {
    font-size: 14px;
  }

  .additional-containers {
    flex-direction: column;
  }

  .container-right {
    margin-left: 0px;
  }

  .container-left,
  .container-right {
    margin: 10px 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .new-dashboard-container {
    display: none;
  }

  .customer-row,
  .customer-header {
    display: none;
  }
}
