/* src/style/CategoryManagement.css */

.category-management-container {
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  /* Removed height: 100vh to allow the container to take available space */
  height: 100%; /* Ensure it takes the full height of its parent */
  box-sizing: border-box;
}

.header,
.title-section {
  flex-shrink: 0; /* Prevent these sections from shrinking */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  font-size: 18px;
  font-family: "Georgia", serif;
  margin-bottom: 0;
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.category-management-title {
  font-size: 24px;
  font-family: "Georgia", serif;
  margin-top: 0;
}

.add-category-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-category-box {
  background-color: #000;
  color: white;
  padding: 10px 20px;
  font-family: "Georgia", serif;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 0;
  align-self: flex-end;
}

.add-category-box:hover {
  background-color: #444; /* Slight darken on hover */
}

.add-category-form {
  margin-top: 20px;
  flex-shrink: 0; /* Prevent from shrinking */
}

.add-category-title {
  font-size: 18px;
  font-family: "Georgia", serif;
  margin-bottom: 10px;
}

.form-group-unique {
  display: flex;
  flex-direction: column;
  font-family: "Georgia", serif;
  font-size: 16px;
}

.form-group-unique input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.button-group {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.done-button,
.cancel-button {
  background-color: #000;
  color: white;
  padding: 10px 20px;
  font-family: "Georgia", serif;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 0;
}

.done-button:hover,
.cancel-button:hover {
  background-color: #444; /* Slight darken on hover */
}

.categories-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
  flex-grow: 1; /* Allow the container to take available space */
  padding-right: 10px; /* Optional: To prevent scrollbar from overlapping content */
}

.category-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  width: calc(33.333% - 20px); /* Adjust width as per design */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.category-name {
  font-family: "Georgia", serif;
  font-size: 18px;
  font-weight: bold;
}

.category-date {
  font-size: 10px;
  color: #888;
  margin-left: 10px;
}

.category-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.action-button {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  flex-grow: 1;
  text-align: center;
}

.view-products-button {
  background-color: #007bff;
  color: white;
}

.delete-category-button {
  background-color: #dc3545;
  color: white;
}

.view-products-button:hover {
  background-color: #0056b3;
}

.delete-category-button:hover {
  background-color: #c82333;
}

.status-message {
  margin-top: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  color: red;
  text-align: center;
}

.status-message.loading {
  color: orange;
}

/* Responsive Design Adjustments */
@media (max-width: 992px) {
  .category-box {
    width: calc(50% - 20px); /* Two columns on medium screens */
  }
}

@media (max-width: 600px) {
  .category-box {
    width: 100%; /* Single column on small screens */
  }
}

/* Optional: Scrollbar Styling */
.categories-list::-webkit-scrollbar {
  width: 8px;
}

.categories-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.categories-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.categories-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Spinner Styling (if implemented) */
.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #444;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
