.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.paginationCircle {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.paginationCircle.active {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Media query to hide pagination on desktop for Item.js */
@media (min-width: 1024px) {
  .hideOnDesktop {
    display: none;
  }
}
