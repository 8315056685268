.view-orders-container {
  padding: 20px 20px 0 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  font-size: 18px;
  font-family: "Georgia", serif;
  margin-bottom: 0; /* Remove margin to align properly */
}

.search-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
  padding-bottom: 4px;
}

.icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.search-input {
  border: none;
  outline: none;
  margin-left: 8px;
  font-family: "Didot Bold";
  font-weight: 100;
  font-size: 14px;
  color: #707070;
  background: transparent;
  width: 200px; /* Adjust the width as needed */
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px; /* Adjusted for better spacing */
}

.view-orders-title {
  font-size: 24px;
  font-family: "Georgia", serif;
  margin-top: 0; /* Remove margin-top to align properly */
}

.sort-container {
  display: flex;
  align-items: center;
  font-family: "Georgia", serif;
  font-size: 16px;
  color: #707070;
}

.orders-table {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto; /* Enable vertical scrolling */
}

.scrollable-content {
  display: flex;
  flex-direction: column;
  min-width: 1300px; /* Set a minimum width to allow horizontal scrolling */
}

.orders-header {
  display: flex;
  border-bottom: 1px solid #707070;
  font-family: "Georgia", serif;
  font-weight: bold;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

.view-header-cell {
  flex: 1;
  padding: 10px;
  border-right: 1px solid #707070;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-header-cell:last-child {
  border-right: none;
}

.view-header-cell-wide {
  flex-grow: 2;
}

.orders-row {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.order-cell {
  flex: 1;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-cell:last-child {
  border-right: none;
}

.order-cell-wide {
  flex-grow: 2;
}

.orders-row:last-child {
  border-bottom: none;
}

.order-cell-wrap {
  white-space: normal;
  word-wrap: break-word;
}

.delivery-status-dropdown {
  padding: 5px 10px;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-family: "Georgia", serif;
  font-size: 14px;
  color: #333;
  outline: none;
  cursor: pointer;
  width: 100%; /* Makes the dropdown take the full width of the cell */
}

.delivery-status-dropdown:focus {
  border-color: #555;
}

.delivery-status-dropdown option {
  padding: 5px;
  background-color: #fff;
  font-family: "Georgia", serif;
  font-size: 14px;
  color: #333;
}

.sort-dropdown {
  padding: 5px 10px;
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-family: "Georgia", serif;
  font-size: 14px;
  color: #333;
  outline: none;
  cursor: pointer;
}

.sort-dropdown:focus {
  border-color: #555;
}

.finished-orders-text {
  font-family: "Georgia", serif;
  font-size: 16px;
  color: #514e4e;
  text-decoration: underline;
  display: inline-block;
  margin-right: 30px; /* Space between Finished Orders and Sort By */
  cursor: pointer; /* Makes it clickable */
}

.right-section {
  display: flex;
  align-items: center;
}
