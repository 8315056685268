.container * {
  box-sizing: border-box;
}

.container {
  font-family: "Didot Bold";
  padding: 20px;
  color: #333;
  scroll-behavior: smooth;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5em;
  margin: 5px 0 20px;
}

.nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.navItem {
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  color: inherit;
}

.navItem:hover {
  text-decoration: underline;
}

.section {
  background-color: #f0f0f0;
  padding: 20px;
  margin: 10px 0;
}

.contentContainer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.textContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start; /* Left-align text */
}

.imageContainer {
  flex: 1;
  height: auto;
  min-height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sectionTitle {
  font-size: 1.5em;
  text-align: start; /* Left-align title */
}

.sectionText {
  font-family: "Didot Regular", serif;
  font-size: 1em;
  line-height: 1.5;
  margin-top: 10px;
  text-align: start; /* Left-align text */
  font-weight: normal;
}

.sectionText a {
  color: #0073e6;
  text-decoration: none;
}

/* Responsive layout for mobile devices */
@media (max-width: 768px) {
  .contentContainer {
    flex-direction: column-reverse;
    align-items: center;
  }

  .textContainer,
  .imageContainer {
    width: 100%;
  }

  /* Ensure texts are left-aligned */
  .textContainer {
    padding: 10px;
    text-align: start;
  }

  .sectionTitle {
    text-align: start;
  }

  .sectionText {
    text-align: start;
    font-size: 1em; /* Adjust if needed */
    line-height: 1.5;
  }
}
