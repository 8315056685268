.user-management-container {
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  font-size: 18px;
  font-family: "Georgia", serif;
  margin-bottom: 0;
}

.sort-container {
  display: flex;
  align-items: center;
  font-family: "Georgia", serif;
  font-size: 16px;
  color: #707070;
}

.sort-option {
  margin-left: 10px;
  cursor: pointer;
  color: #000;
  font-weight: bold;
}

.sort-option:hover {
  text-decoration: underline;
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.user-management-title {
  font-size: 24px;
  font-family: "Georgia", serif;
  margin-top: 0;
}

.users-table {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.users-header {
  display: flex;
  border-bottom: 1px solid #707070;
  font-family: "Georgia", serif;
  font-weight: bold;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

.users-header-cell {
  padding: 10px;
  border-right: 1px solid #707070;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.users-header-cell:first-child {
  flex: 1.5;
}

.users-header-cell:nth-child(2) {
  flex: 1;
}

.users-header-cell:nth-child(3) {
  flex: 1.5;
}

.users-header-cell:nth-child(4) {
  flex: 2.5;
}

.users-header-cell:nth-child(5),
.users-header-cell:nth-child(6) {
  flex: 1;
}

.header-cell:last-child {
  border-right: none;
}

.user-cell {
  padding: 10px;
  border-right: 1px solid #e0e0e0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-cell:first-child {
  flex: 1.5;
}

.user-cell:nth-child(2) {
  flex: 1;
}

.user-cell:nth-child(3) {
  flex: 1.5;
}

.user-cell:nth-child(4) {
  flex: 2.5;
}

.user-cell:nth-child(5),
.user-cell:nth-child(6) {
  flex: 1;
}

.user-cell:last-child {
  border-right: none;
}

.users-row {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.users-row:last-child {
  border-bottom: none;
}
