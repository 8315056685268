.promo-section {
  background-color: white;
  text-align: left;
  padding: 20px;
  width: 100%;
}

.promo-text {
  color: #707070;
  font-size: 13px;
  opacity: 0.9;
}

.email-signup {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: calc(100% - 40px); /* Adjust width to account for padding */
  margin-top: 30px;
  opacity: 0.8;
  margin-bottom: 10px;
}

.email-input {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid #707070;
  padding-bottom: 8px; /* Adjust as needed to move placeholder text up */
  font-family: "Georgia";
  font-weight: 100;
  font-size: 13px;
  margin-right: 15px; /* Space between the input and the button */
  background: transparent; /* Ensures no background interferes */
  outline: none; /* Removes focus outline */
  border-radius: 0; /* Ensure no border radius */
}

.email-input::placeholder {
  line-height: 1.2; /* Adjust line height to control vertical placement */
  vertical-align: middle; /* Align text vertically */
}

.signup-button {
  padding: 10px 20px; /* Adjust padding if needed after changing text size */
  background-color: white;
  border: 1px solid #707070; /* Visible border for the button */
  cursor: pointer;
  font-family: "Didot Bold", serif; /* Assuming you want to keep the Didot font */
  font-size: 12px; /* Reduced font size */
}

.text-sections {
  margin-top: 10px; /* Provides spacing between sections */
}

.footer-details-clicks {
  cursor: pointer;
}

.text-section {
  margin-top: 10px; /* Provides spacing between text sections */
  padding: 5px 20px; /* Add padding to match collapsible opened state */
  font-size: 12px;
  color: #707070; /* Text color for content */
  line-height: 1.2;
  font-family: "Georgia";
  opacity: 0.8;
}

.text-section h3 {
  font-family: "Georgia", Serif;
  font-size: 13px;
  margin: 0px;
  color: #707070;
  font-weight: 500;
}

/* Media query for desktop view */
@media (min-width: 768px) {
  .promo-collapsible-container {
    display: flex;
    width: calc(
      100% - 80px
    ); /* Adjust width to account for horizontal padding */
    padding: 0 40px; /* Horizontal padding for the edges */
    gap: 10px; /* Reduce gap between sections */
  }

  .promo-section {
    width: 30%;
    padding: 20px;
  }

  .text-sections {
    width: 30%;
    display: flex;
    flex-direction: column; /* Stack text sections vertically */
    gap: 10px; /* Add some space between text sections */
    margin-top: 5px;
    padding: 20px;
    margin-left: 70px;
  }

  .email-signup {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-end; /* Align items to the right */
    width: 100%; /* Maintain the width restriction */
  }

  .email-input {
    width: 100%; /* Let the input take full width of the container */
    margin-right: 0; /* Remove any right margin */
  }

  .signup-button {
    margin-top: 10px; /* Add some space between the input and the button */
    width: auto; /* Adjust width to the content of the button */
    padding: 10px 35px; /* Adjust padding if needed after changing text size */
    font-size: 10px; /* Reduced font size */
  }
}
