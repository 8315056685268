/* src/style/AdminPanel.css */
.admin-panel-container {
  display: flex;
  height: 100vh;
  background-color: #f5f5f5;
}

.admin-nav-container {
  background: white;
  color: black;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.brand-text {
  font-weight: bold;
  font-size: 18px;
  font-family: "Didot Bold";
  color: #000;
  opacity: 0.7;
  text-align: center;
  flex-grow: 1;
}

.admin-icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.left-icon {
  flex-shrink: 0;
}

.right-icon {
  visibility: hidden;
  flex-shrink: 0;
}

.admin-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.admin-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1001;
  padding: 20px;
  box-sizing: border-box;
}

.admin-drawer.open {
  transform: translateX(0);
}

.admin-drawer-header {
  display: flex;
  justify-content: flex-end;
}

.admin-drawer-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 50px);
  justify-content: space-between;
}

.admin-link {
  font-family: "Georgia";
  color: #707070;
  font-size: 16px;
  cursor: pointer;
}

.admin-link:hover {
  color: #000;
}

.drawer-content-top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.drawer-content-bottom {
  text-align: center;
  font-family: "Didot Bold";
  color: #000;
  opacity: 0.7;
}

.main-content {
  padding-top: 70px;
  width: 100%;
  text-align: left;
  padding: 70px 20px 10px 20px;
}

.please-switch-to-desktop {
  display: none;
  font-size: 24px;
  font-family: "Georgia", serif;
  text-align: center;
  padding-top: 50px;
}

@media (max-width: 1023px) {
  .admin-nav-container {
    display: none; /* Hide the navbar on smaller screens */
  }

  .main-content {
    display: none; /* Hide the main content on smaller screens */
  }

  .please-switch-to-desktop {
    display: block; /* Show the desktop switch message */
  }
}

@media (min-width: 1024px) {
  .admin-nav-container {
    display: none; /* Hide the navbar on larger screens */
  }

  .admin-drawer {
    position: static;
    transform: none;
    width: 20%;
    border-right: none;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .admin-overlay {
    display: none; /* Hide the overlay */
  }

  .main-content {
    width: 80%;
    padding: 10px;
  }

  .admin-drawer-header {
    display: none; /* Hide the drawer header */
  }

  .please-switch-to-desktop {
    display: none; /* Hide the desktop switch message */
  }
}
