@keyframes loadingAnimation {
  0% {
    background-color: rgba(112, 112, 112, 0.2);
  }
  50% {
    background-color: rgba(112, 112, 112, 0.4);
  }
  100% {
    background-color: rgba(112, 112, 112, 0.2);
  }
}

.products-grid-unique {
  display: grid;
  gap: 3px;
  margin-bottom: 10px;
}

.product-wrapper-unique {
  position: relative;
}

/* Spinner Container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Adjust as needed */
}

/* Spinner Styles */
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(112, 112, 112, 0.2); /* Light grey */
  border-top: 4px solid rgba(112, 112, 112, 1); /* Darker grey */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.load-more-container {
  text-align: center;
  margin: 30px 0;
}

.load-more-text {
  color: rgba(112, 112, 112, 0.8);
  border: 1px solid gray;
  padding: 15px 10px;
  cursor: pointer;
  font-size: 14px;
}

/* Spinner Animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.product-container-unique {
  /* background-color: rgba(231, 218, 218, 0.648); */
  /* opacity: 20%; */
  background-color: rgba(112, 112, 112, 0.2);
  width: 100%;
  margin-top: 10px;
  animation: loadingAnimation 1.5s infinite; /* Apply the animation */
  cursor: pointer;
}

.product-details-unique {
  margin-top: 10px;
  text-align: left;
  margin-left: 10px;
  position: relative;
}

.new-in-unique,
.item-name-unique,
.price-unique {
  font-family: "Didot bold";
  margin-bottom: 10px; /* Add space between product details */
}

.new-in-unique {
  font-size: 11px;
  opacity: 0.7;
  color: rgba(112, 112, 112, 0.8);
}

.item-name-unique {
  font-size: 12px;
  opacity: 1;
  color: rgba(112, 112, 112, 1);
}

.price-unique {
  font-size: 12px;
  opacity: 1;
  color: rgba(112, 112, 112, 1);
}

.unfilled-star {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.load-more-wrapper-unique {
  text-align: center;
  margin-bottom: 40px;
}

.load-more-container-unique {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(112, 112, 112, 1);
  font-family: "Didot bold";
  font-size: 14px;
  text-align: center;
  padding: 10px 20px;
  margin: 20px auto 0;
  display: inline-block;
}

.separator-unique {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 100%;
  margin-top: 20px;
}

.discover-text-unique {
  font-family: "Georgia";
  font-size: 14px;
  color: rgba(112, 112, 112, 0.8);
  margin-top: 40px;
  padding: 0 35px;
}

.product-container-unique img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  top: 0;
  left: 0;
}

/* Mobile small screens (320px) */
@media (max-width: 320px) {
  .products-grid-unique {
    grid-template-columns: 1fr 1fr;
  }
  .product-container-unique {
    height: 280px;
  }
}

/* Mobile medium screens (375px) */
@media (min-width: 321px) and (max-width: 375px) {
  .products-grid-unique {
    grid-template-columns: 1fr 1fr;
  }
  .product-container-unique {
    height: 300px;
  }
}

/* Mobile large screens (425px) to tablet (767px) */
@media (min-width: 376px) and (max-width: 767px) {
  .products-grid-unique {
    grid-template-columns: 1fr 1fr;
  }
  .product-container-unique {
    height: calc(285px + ((100vw - 376px) / (767 - 376)) * (600 - 340));
  }
}

/* Tablets (768px) to (990px) */
@media (min-width: 768px) and (max-width: 990px) {
  .products-grid-unique {
    grid-template-columns: repeat(3, 1fr);
  }
  .product-container-unique {
    height: calc(340px + ((100vw - 768px) / (990 - 768)) * (500 - 350));
  }
}

/* Screens (991px) to (1023px) */
@media (min-width: 991px) and (max-width: 1023px) {
  .products-grid-unique {
    grid-template-columns: repeat(4, 1fr);
  }
  .product-container-unique {
    height: 350px;
  }
}

/* Small desktops (1024px) and up */
@media (min-width: 1024px) {
  .products-grid-unique {
    grid-template-columns: repeat(4, 1fr);
  }
  .product-container-unique {
    height: calc(350px + ((100vw - 1024px) / (1440 - 1024)) * (510 - 350));
  }
}

/* Add this CSS code to your stylesheet */

@media screen and (max-width: 425px) {
  .form-group label,
  .form-input,
  .form-group select {
    font-size: 12px; /* Adjust the font size as needed */
  }

  .customize-option span,
  .customize-category h4 {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .form-group label,
  .form-input,
  .form-group select {
    font-size: 11px; /* Further reduce the font size as needed */
  }

  .form-group select option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .customize-option span,
  .customize-category h4 {
    font-size: 11px; /* Further reduce the font size as needed */
  }
}
