/* settings.css */

.settings-container {
  padding: 20px 20px 0 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1; /* Allow the container to grow and fill available space */
  overflow-y: auto; /* Enable vertical scrolling within the container */
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  font-size: 18px;
  font-family: "Georgia", serif;
  margin-bottom: 0;
}

.title-section {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.quick-settings-title {
  font-size: 24px;
  font-family: "Georgia", serif;
  margin-top: 0;
}

.promo-pictures-section {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.promo-picture {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  font-family: "Georgia", serif;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 150px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.promo-picture:hover {
  background-color: #f0f0f0;
}

.fx-password-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  flex: 1; /* Allow the container to grow and fill available space */
}

.fx-settings-section,
.change-password-section {
  flex: 1; /* Allow sections to fill available space */
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.fx-title,
.change-password-title {
  font-size: 18px;
  font-family: "Georgia", serif;
  margin-bottom: 10px;
}

.fx-group,
.form-group-unique-settings {
  display: flex;
  flex-direction: column;
  font-family: "Georgia", serif;
  font-size: 16px;
  margin-bottom: 10px;
}

.fx-input,
.form-group-unique-settings input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.save-fx-button,
.change-password-button {
  background-color: #000;
  color: white;
  padding: 10px 20px;
  font-family: "Georgia", serif;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 0;
  align-self: flex-start;
  margin-top: auto; /* Push the button to the bottom */
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 15px 30px;
  border-radius: 4px;
  z-index: 1000;
  max-width: 300px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s;
}

.notification.show {
  opacity: 1;
}

.notification.hide {
  opacity: 0;
}
