.react-select__menu {
  z-index: 9999;
}

.add-product-container-unique {
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  /* overflow: hidden; */
  background-color: rgba(245, 245, 245, 1);
  justify-content: space-between;
}

.add-product-header-unique {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.back-and-title-container {
  display: flex;
  align-items: center;
}

.back-button-unique {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-icon-unique {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.add-product-title-unique {
  margin-left: 20px;
  font-family: "Georgia", serif;
  font-size: 18px;
}

.upload-button-container-unique {
  display: flex;
  align-items: center;
}

.upload-button-unique {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  font-family: "Georgia", serif;
  font-size: 16px;
  border: 1px solid #707070;
  cursor: pointer;
  margin-left: auto;
}

.content-container-unique {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-grow: 1;
  padding-top: 10px;
  padding-bottom: 20px;
}

.split-section-unique {
  background-color: transparent;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.small-section-unique {
  flex: 0 0 30%;
}

.medium-section-unique {
  flex: 0 0 30%;
}

.large-section-unique {
  flex: 0 0 35%;
  overflow: hidden;
}

.split-title-unique {
  font-family: "Georgia", serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.product-inner-container-unique,
.category-inner-container-unique,
.new-in-inner-container-unique,
.colors-inner-container-unique,
.sleeve-length-inner-container-unique,
.top-length-inner-container-unique,
.product-images-inner-container-unique,
.measurements-inner-container-unique {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  padding: 20px;
}

.product-images-inner-container-unique {
  margin-bottom: 30px;
}

.form-label-unique {
  font-family: "Georgia", serif;
  font-size: 14px;
  padding-right: 20px;
}

.form-input-unique,
.form-input-small-unique,
.form-select-unique,
.form-select-small-unique {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-input-small-unique,
.form-select-small-unique {
  width: 100px;
}

.first-child,
.second-child,
.third-child {
  margin-bottom: 20px;
}

.image-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-images-inner-container-unique {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image-section-unique {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-section-title-unique {
  font-family: "Georgia", serif;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.add-files-link-unique {
  font-family: "Georgia", serif;
  font-size: 16px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  white-space: pre-line;
}

.images-container-unique {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.images-container-unique::-webkit-scrollbar {
  display: none;
}

.cancel-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.image-container-unique {
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  width: 150px;
  height: 200px;
  background-color: rgba(112, 112, 112, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  align-self: flex-start;
  flex-shrink: 0;
}

.color-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.color-dialog {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.color-name-input {
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.color-dialog-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.color-dialog-buttons button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.selected-colors {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.selected-color {
  display: flex;
  align-items: center;
  gap: 5px;
}

.color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.remove-color {
  cursor: pointer;
  color: red;
}
.upload-button-unique.disabled {
  background-color: #ccc;
  cursor: not-allowed;
  border: none;
  pointer-events: none;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  background: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.notification.show {
  opacity: 1;
}

.new-product-section {
  margin-bottom: 20px;
}

.new-section-title {
  font-family: "Georgia", serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.new-product-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* max-height: 270px;
  overflow-y: auto; */
}

.new-form-label {
  font-family: "Georgia", serif;
  font-size: 14px;
  margin-bottom: 5px;
}

.new-form-input,
.new-form-input-small {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.new-form-input-small {
  width: 100px;
}

.new-form-row {
  display: flex;
  gap: 10px;
}

.new-form-group {
  display: flex;
  flex-direction: column;
}

.new-form-group:first-child {
  flex: 0 0 30%;
}

.new-form-group:nth-child(2) {
  flex: 1;
}

.details-section {
  margin-bottom: 20px;
}

.details-section-title {
  font-family: "Georgia", serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.details-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.details-group {
  display: flex;
  flex-direction: column;
}

.details-label {
  font-family: "Georgia", serif;
  font-size: 14px;
  margin-bottom: 5px;
}

.details-textarea {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
  min-height: 90px;
}

.delivery-section {
  margin-bottom: 20px;
}

.delivery-title {
  font-family: "Georgia", serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.delivery-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
}

.delivery-row {
  display: flex;
  gap: 20px;
}

.delivery-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.delivery-label {
  font-family: "Georgia", serif;
  font-size: 14px;
  margin-bottom: 5px;
}

.delivery-input {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.delivery-dates {
  font-family: "Georgia", serif;
  font-size: 14px;
  color: #555;
}

.customize-section {
  margin-bottom: 20px;
}

.customize-title {
  font-family: "Georgia", serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.color-section,
.sleeve-length-section,
.trouser-length-section,
.cap-size-section,
.height-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: left;
  width: 100%;

  margin-bottom: 10px;
}

.customize-container {
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.customize-container p {
  margin: 20px 0;
  font-family: "Georgia", serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.color-one {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.customize-button-container {
  position: relative;
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.customize-button {
  display: inline-block; /* Ensures the button sizes correctly */
  text-align: center; /* Centers the text horizontally */
  padding: 10px 20px; /* Adjust padding as needed */
  line-height: normal; /* Reset line height to avoid text alignment issues */
  font-family: "Georgia", serif; /* Ensures consistent font */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer; /* Ensures the button looks clickable */
  border: 1px solid #ccc; /* Adds a border to the button */
  border-radius: 4px; /* Adds rounded corners to the button */
  background-color: #fff; /* Ensures the button has a white background */
}

.customize-button::-ms-expand {
  display: none;
}

.customize-button:hover {
  border-color: #999;
}

.customize-button:focus {
  border-color: #666;
}

.dropdown-menu {
  position: absolute;
  top: -100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px;
  padding: 5px 0;
}

.dropdown-menu div {
  padding: 10px;
  cursor: pointer;
  font-family: "Georgia", serif;
  font-size: 14px;
}

.color-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
}

.color-title {
  font-family: "Georgia", serif;
  font-size: 14px;
  margin-bottom: 5px;
}

.color-options {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.color-dropdown,
.price-dropdown,
.color-french-name-input,
.section-name-input-french {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(50% - 10px);
}

.color-options p {
  font-family: "Georgia", serif;
  font-size: 14px;
  color: #555;
}

.price-dropdown.disabled {
  background-color: #e0e0e0;
  color: #999;
  cursor: not-allowed;
  pointer-events: none;
}

.price-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.price-input {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
}

.price-input label {
  font-weight: bold;
}

.price-input input {
  padding: 8px 12px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.okay-button {
  padding: 8px 20px;
  font-family: "Georgia", serif;
  font-size: 14px;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.okay-button:hover {
  background-color: #45a049;
}

.sleeve-length-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  margin-top: 20px;
}

.sleeve-length-title {
  font-family: "Georgia", serif;
  font-size: 16px;
  margin-bottom: 10px;
}

.sleeve-length-options {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.sleeve-length-dropdown,
.price-dropdown {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(50% - 10px);
}

.sleeve-length-note {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.selected-sleeve-lengths {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.selected-length {
  display: flex;
  align-items: center;
  gap: 5px;
}

.length-name {
  font-family: "Georgia", serif;
  font-size: 14px;
}

.remove-length {
  cursor: pointer;
  color: red;
}

.trouser-length-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  margin-top: 20px;
}

.trouser-length-title {
  font-family: "Georgia", serif;
  font-size: 16px;
  margin-bottom: 10px;
}

.trouser-length-options {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.trouser-length-dropdown,
.price-dropdown {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(50% - 10px);
}

.selected-trouser-lengths {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.selected-length {
  display: flex;
  align-items: center;
  gap: 5px;
}

.length-name {
  font-family: "Georgia", serif;
  font-size: 14px;
}

.remove-length {
  cursor: pointer;
  color: red;
}

.cap-size-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  margin-top: 20px;
}

.cap-size-title {
  font-family: "Georgia", serif;
  font-size: 16px;
  margin-bottom: 10px;
}

.cap-size-options {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.cap-size-dropdown,
.price-dropdown {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(50% - 10px);
}

.selected-cap-sizes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.selected-size {
  display: flex;
  align-items: center;
  gap: 5px;
}

.size-name {
  font-family: "Georgia", serif;
  font-size: 14px;
}

.remove-size {
  cursor: pointer;
  color: red;
}

.height-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.height-title {
  font-family: "Georgia", serif;
  font-size: 16px;
  margin-bottom: 10px;
}

.height-options {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.height-dropdown,
.price-dropdown {
  padding: 10px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(50% - 10px);
}

.selected-heights {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.selected-height {
  display: flex;
  align-items: center;
  gap: 5px;
}

.height-name {
  font-family: "Georgia", serif;
  font-size: 14px;
}

.remove-height {
  cursor: pointer;
  color: red;
}

.visibility-control-unique {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.visibility-label-unique {
  font-family: "Georgia", serif;
  font-size: 14px;
  margin-right: 10px;
}

.visibility-select-unique {
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
}

.visibility-select-unique:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/*new client section*/

.new-client-title {
  font-family: "Georgia", serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.new-client-container {
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.new-client-container p {
  margin: 20px 0;
  font-family: "Georgia", serif;
  font-size: 14px;
}

.new-client-button-container {
  position: relative;
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.new-client-button {
  padding: 10px 40px 10px 20px;
  font-family: "Georgia", serif;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../icons/down.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: 15px;
}

.new-client-button::-ms-expand {
  display: none;
}

.new-client-button:hover {
  border-color: #999;
}

.new-client-button:focus {
  border-color: #666;
}

.new-section-container {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
}

.new-section-title {
  font-family: "Georgia", serif;
  font-size: 16px;
  margin-bottom: 10px;
}

.custom-subsection-row {
  margin-top: 10px;
}

.fields-section {
  display: flex;
}

.add-subsection-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-subsection-button:hover {
  background-color: #0056b3;
}

/* Style the section name input */
.section-name-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  font-family: "Georgia", serif;
}

/* Style for subsection and price inputs */
.custom-subsection-row input[type="text"],
.custom-subsection-row input[type="number"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  font-family: "Georgia", serif;
}

.custom-subsection-row input[type="text"]:focus,
.custom-subsection-row input[type="number"]:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* Button styling */
.add-subsection-button {
  background-color: #007bff; /* Blue color */
  color: white;
  padding: 10px 20px;
  font-family: "Georgia", serif;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.add-subsection-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.new-section-title {
  font-family: "Georgia", serif;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.top-title {
  align-content: center;
}

.top-section-unique {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.custom-select-container {
  width: 250px; /* Fixed width */
}

.custom-select-container .react-select__control {
  min-height: 38px; /* Set a minimum height */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap inside the box */
  overflow-y: auto; /* Enable vertical scrolling if items exceed the height */
}

.custom-select-container .react-select__multi-value {
  display: inline-flex;
  margin: 2px; /* Optional: Add spacing between items */
}

.trouser-waist-section-unique {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.trouser-waist-title {
  align-content: center;
}

.custom-select-container {
  width: 250px; /* Fixed width */
}

.custom-select-container .react-select__control {
  min-height: 38px; /* Set a minimum height */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap inside the box */
  overflow-y: auto; /* Enable vertical scrolling if items exceed the height */
}

.cap-size-section-unique {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.cap-size-title {
  align-content: center;
}

.custom-select-container {
  width: 250px; /* Fixed width */
}

.custom-select-container .react-select__control {
  min-height: 38px; /* Set a minimum height */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap inside the box */
  overflow-y: auto; /* Enable vertical scrolling if items exceed the height */
}

.height-section-unique {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.height-title {
  align-content: center;
}

.custom-select-container {
  width: 250px; /* Fixed width */
}

.custom-select-container .react-select__control {
  min-height: 38px; /* Set a minimum height */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap inside the box */
  overflow-y: auto; /* Enable vertical scrolling if items exceed the height */
}

.shoe-size-section-unique {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.shoe-size-title {
  align-content: center;
}

.custom-select-container {
  width: 250px; /* Fixed width */
}

.custom-select-container .react-select__control {
  min-height: 38px; /* Set a minimum height */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap inside the box */
  overflow-y: auto; /* Enable vertical scrolling if items exceed the height */
}

.toggle-container {
  margin-top: 25px; /* Adjust spacing as needed */
  display: flex;
  align-items: center;
}

.toggle-switch {
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-item {
  display: flex;
  align-items: center;
}
.toggle-switch:checked {
  background-color: #000;
}

.toggle-switch:before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease;
}

.toggle-switch:checked:before {
  transform: translateX(20px);
}

.toggle-label {
  margin-left: 10px; /* Space between the toggle and label */
  font-family: "Georgia", serif;
  font-size: 14px;
}

.unique-size-section-unique {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.unique-size-title {
  align-content: center;
}
