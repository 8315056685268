/* src/style/Admin.css */
.admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-top: 50px; /* Adjust padding to ensure the form is below the navbar */
  box-sizing: border-box;
}

.login-header {
  font-size: 24px;
  font-family: "Georgia", serif;
  margin-bottom: 20px; /* Space between header and form */
}

.login-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form input {
  width: calc(100% - 20px); /* Adjust width to fit inside the container */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.login-form button {
  width: 50%;
  padding: 10px;
  margin-top: 25px;
  border: none;
  background-color: #000;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #444;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}
