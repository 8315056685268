body,
html {
  -webkit-tap-highlight-color: transparent !important;
  overflow-x: hidden; /* Hides horizontal scroll */
}

@keyframes loadingAnimation {
  0% {
    background-color: rgba(112, 112, 112, 0.2);
  }
  50% {
    background-color: rgba(112, 112, 112, 0.4);
  }
  100% {
    background-color: rgba(112, 112, 112, 0.2);
  }
}

.container {
  padding: 0 20px; /* Horizontal padding 20px, no vertical padding */
  max-width: 600px;
  margin: 0 auto; /* Center the container */
}

/* src/App.css */
@font-face {
  font-family: "Georgia";
  src: url("./fonts/georgia.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Didot Bold";
  src: url("./fonts/didot_bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Georgia", serif;
}

.viewport {
  background-color: rgba(112, 112, 112, 0.2);
  color: white;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  animation: loadingAnimation 1.5s infinite; /* Apply the animation */
}

.social-links-container {
  width: 100%;
  padding: 0 0 0 0; /* Remove padding around social links container */
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0; /* Padding only inside the links container to space icons away from the HRs */
}

.social-icon {
  margin: 0 9.5px; /* Space between icons */
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.styled-hr-home {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 100%;
  margin: 0;
}
.styled-hr-home-bottom {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 100%;
  padding-top: 30px;
}

/* Styled horizontal rule */
.styled-hr {
  border: none;
  border-top: 0.5px solid #707070;
  opacity: 0.5;
  width: 100%;
  margin: 0;
}

.homme-button {
  background-color: white;
  color: #707070;
  border: none;
  padding: 16px 60px;
  font-family: "Didot Bold", serif;
  font-size: 16px;
  cursor: pointer;
  outline: none; /* Remove blue outline when clicked */
}

.homme-button:focus,
.homme-button:active {
  outline: none; /* Removes focus outline */
  box-shadow: none; /* Removes box-shadow (if any) */
  background-color: white; /* Keep background same */
}

.homme-button:hover {
  opacity: 0.8;
}

.footer-details {
  background-color: #efefef;
  color: #707070;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box; /* Ensures padding doesn't add to width */
}

.footer-details p {
  font-family: "Georgia", serif;
  font-size: 12px;
  margin: 15px 0;
}

.dropdown-container {
  padding-left: 10px; /* Mobile left padding */
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 15px; /* Space between dropdowns */
}

.dropdown-toggle {
  background-color: white;
  border: none; /* No border */
  padding: 10px;
  cursor: pointer;
  font-family: "Georgia", serif;
  font-size: 12px;
  color: #707070;
  display: flex;
  align-items: center;
}

.dropdown-icon {
  margin-left: 10px;
  width: 12px;
  height: 12px;
}

.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Invisible layer */
  z-index: 999; /* Ensure it covers everything */
  pointer-events: none; /* Disable pointer events */
}

.dropdown.open .dropdown-overlay {
  pointer-events: auto; /* Enable pointer events when dropdown is open */
}

.dropdown-menu2 {
  position: absolute;
  left: 10%;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px; /* Ensures a minimum width */
  width: max-content; /* Allows the width to adjust based on content */
  max-width: 100%; /* Ensures it doesn't overflow the container */
  max-height: 250px; /* Set maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-family: "Georgia", serif;
  font-size: 12px;
  color: #707070;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 10px;
}

.dropdown-select-all {
  text-decoration: underline;
}

/* Desktop view adjustments for social icons */
@media (min-width: 768px) {
  .social-icon {
    margin: 0 25px; /* Increased space between icons for desktop views */
  }

  .dropdown-container {
    padding-left: 30px; /* Desktop left padding */
  }

  .dropdown-item {
    font-size: 12px;
  }

  .dropdown-toggle {
    font-size: 12px;
  }

  .footer-details {
    flex-direction: row;
    justify-content: space-around;
  }

  .footer-details p {
    font-size: 14px; /* Aligning desktop font sizes */
  }
}

/* Adjust for mobile */
@media (max-width: 767px) {
  .viewport {
    height: calc(100vh - 30px); /* Adjust for mobile navbar height */
  }
}
