.collapsible {
  margin-top: 10px; /* Provides spacing between collapsible sections */
  margin-bottom: 5px;
}

.collapsible-header {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  opacity: 0.7;
  margin-bottom: 10px; /* Reduced bottom margin */
  padding: 0px 20px; /* Adjusted padding to reduce clickable area */
  font-size: 14px;
  color: #000; /* Matching your color scheme */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: "Georgia";
}

.collapsible-header > * {
  cursor: pointer; /* Only text and icons within the header should show the pointer cursor */
}

.collapsible-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  mask: url("../icons/plus.svg") no-repeat center / contain;
  transition: transform 0.3s ease;
  opacity: 0.8;
}

.collapsible-icon.open {
  transform: rotate(180deg);
  mask: url("../icons/minus.svg") no-repeat center / contain;
}

.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  padding: 0px; /* Add horizontal padding */
  font-size: 13px;
  color: #707070;
  line-height: 1.5;
  font-family: "Georgia";
  opacity: 0.8;
  text-align: left;
}

.collapsible.open .collapsible-content {
  max-height: 1000px; /* Large enough value to ensure it expands fully */
  padding: 5px 20px; /* Adjust padding for the opened state */
}

@media (max-width: 500px) {
  .collapsible-content {
    font-size: 12px;
  }
}
