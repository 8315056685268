.finishedOrdersContainer {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the container takes the full viewport height */
  padding: 20px;
}

.backAndTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10; /* Ensure it's above the scrollable content */
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc; /* Optional: to visually separate the header from the scrollable content */
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backIcon {
  margin-right: 8px;
}

.ordersScrollableSection {
  flex-grow: 1; /* This will allow the section to take up remaining space */
  overflow-y: auto; /* Make the product section scrollable */
}

.ordersTable {
  margin-top: 20px;
}

.orderRow {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px; /* Adds space between each product */
  background-color: #f9f9f9;
}

.orderRow div {
  margin-bottom: 10px; /* Adds space between each detail within a product */
}

.orderRow ul {
  margin-left: 20px;
  list-style-type: disc;
}
